@import url("~reset-css");
:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.u-lead {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 10px; }
  .u-lead--bold {
    color: #7d7d7d;
    font-weight: 900; }

.u-uppercase {
  text-transform: uppercase; }

.u-link {
  color: #21125e;
  text-decoration: underline;
  cursor: pointer; }

.c-option {
  cursor: pointer;
  padding: 1rem 1rem 1rem 2.5rem;
  position: relative; }
  .c-option label {
    cursor: pointer; }
    .c-option label::before {
      display: block;
      position: absolute;
      content: "";
      border-radius: 100%;
      height: 0.5rem;
      width: 0.5rem;
      top: 1.25rem;
      left: 1rem;
      margin: auto;
      box-shadow: 0 0 0 1px #21125e;
      border: 2px solid #fff;
      background-color: #fff; }
  .c-option--selected label::before {
    background-color: #21125e; }
  .c-option--disabled label::before {
    background-color: rgba(196, 196, 196, 0.5);
    box-shadow: 0 0 0 1px #c4c4c4; }
  .c-option input {
    display: none; }

body {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #323232;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%)); }
  body * {
    box-sizing: border-box; }
  body h1 {
    font-size: 4rem;
    line-height: 1; }
  body h3 {
    font-size: 1.5rem;
    line-height: 1.7rem; }
  body h4 {
    font-size: 1.125rem; }
  body a {
    text-decoration: none;
    font-weight: normal;
    color: inherit; }
  body p {
    line-height: 1.5rem; }
  body strong {
    font-weight: 600; }
  body em {
    font-style: italic; }
  body #root,
  body #__next {
    display: flex;
    min-height: 100vh;
    flex-direction: column; }
    body #root > div:first-of-type,
    body #__next > div:first-of-type {
      flex: 1; }
  body .container {
    width: 1140px;
    max-width: 100vw;
    margin: 0 auto;
    padding: 0 1rem; }
    @media (max-width: 992px) {
      body .container {
        width: 100%; } }
  body table {
    width: 100%;
    margin: 3rem 0;
    font-size: 0.875rem; }
    body table th {
      color: #7d7d7d;
      text-align: left;
      padding: 1rem 0.5rem; }
    body table tr {
      color: #323232;
      border-bottom: 1px solid #f6f6f6; }
    body table td {
      padding: 1rem 0.5rem;
      vertical-align: middle; }
      body table td img {
        vertical-align: middle;
        margin-right: 1rem; }
  body .checkbox {
    position: relative;
    display: inline; }
    body .checkbox:before {
      content: "";
      display: block;
      position: absolute;
      left: 0.4rem;
      top: 0.05rem;
      width: 0.3rem;
      height: 0.6rem;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      z-index: 1; }
    body .checkbox input {
      display: none; }
      body .checkbox input:checked ~ span:before {
        background-color: #21125e; }
    body .checkbox span {
      margin-left: 2rem; }
      body .checkbox span:before {
        background-color: #fff;
        border-radius: 0.2rem;
        border: 1px solid #21125e;
        content: "";
        display: block;
        height: 1rem;
        position: absolute;
        top: 0;
        width: 1rem; }

.form-error {
  color: #fe6e76; }
